// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

const images = require.context("images", true);
window.$ = window.jQuery = window.jquery = require("jquery");
require("js/jquery.slimscroll");
require("js/bootstrap.min");
require("js/detect");
require("js/vertical-menu.js");
require("js/toasts");
require("js/core");
require("js/select2.min");
require("datatables.net-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-responsive-bs4");
require("js/bootstrap-datetimepicker.js");
require("jquery");
require("@nathanvda/cocoon");
require("data-confirm-modal");
import "stylesheets/application.scss";

var pnotify = require("src/pnotify/js/pnotify.custom.min");
window.pnotify = pnotify;

import "src/datatables/dataTables.bootstrap4.min.css";
import "src/datatables/buttons.bootstrap4.min.css";
import "src/datatables/responsive.bootstrap4.min.css";
import "src/pnotify/css/pnotify.custom.min";
import "src/select2/select2.min";
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

const show_toast = ({ message, type } = {}) => {
  new pnotify({
    title: false,
    text: message,
    type: type,
    addclass: "custom-notification-alert-" + type + " notification",
    icon: false,
    buttons: {
      sticker: false,
    },
  });
};

window.show_toast = show_toast;

window.addEventListener("turbolinks:load", () => {
  limitPorcentValue();
  inicializarDataTable(".dataTable");
  inicializarDataTablePaginated(".dataTable-paginated");
  inicializarDataTableUsuarios(".dataTableUsuarios");
  inicializarPagyDataTable(".dataTablePagy");

  $(".select2").select2({
    tags: "true",
    width: "resolve",
    dropdownAutoWidth: true,
  });
});

window.to_date = function (date) {
  if (date) {
    let adate = date.split("T")[0];
    let array = adate.split("-");
    let new_date = array[2] + "/" + array[1] + "/" + array[0];
    return new_date;
  } else {
    return "--";
  }
};

let currency_formater = new Intl.NumberFormat("es-ES");

window.to_currency = function (number) {
  if (number) {
    return "$ " + currency_formater.format(number);
  } else {
    return "$ 0";
  }
};

window.to_humanized = function (text) {
  if (text) {
    text = text.replace(/\w\S*/g, function (word) {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    });
    return text;
  } else {
    return "--";
  }
};

var dT_language = {
  sProcessing: "Procesando...",
  sLengthMenu: "Mostrar _MENU_ registros",
  sZeroRecords: "No se encontraron resultados",
  sEmptyTable: "Ningún dato disponible en esta tabla",
  sInfo: "Total: _TOTAL_ registros",
  sInfoEmpty: "Total: _TOTAL_ registros",
  sInfoFiltered: "(filtrado de un total de _MAX_ registros)",
  sInfoPostFix: "",
  sSearch: "Buscar en la página actual:",
  sUrl: "",
  sInfoThousands: ".",
  decimal: ",",
  thousands: ".",
  sLoadingRecords: "Cargando...",
  oPaginate: {
    sFirst: "Primero",
    sLast: "Último",
    sNext: "Siguiente",
    sPrevious: "Anterior",
  },
  oAria: {
    sSortAscending: ": Activar para ordenar la columna de manera ascendente",
    sSortDescending: ": Activar para ordenar la columna de manera descendente",
  },
};

window.dT_language = dT_language;

window.inicializarDataTable = function (clase) {
  $(clase).DataTable({
    responsive: false,
    aaSorting: [],
    language: dT_language,
    paging: true,
    searching: true,
    columnDefs: [
      {
        targets: "no-sort",
        orderable: false,
      },
    ],
  });
  $(clase).parent().addClass("table_scroll");
};

window.getValuesInputs = function (clase) {
  return $(clase)
    .map(function () {
      return this.value;
    })
    .toArray();
};

window.getSelectedTexts = function (clase) {
  return $(clase + " option:selected")
    .map(function () {
      return $(this).text();
    })
    .toArray();
};

const destroy_dataTableObject = function () {
  //
  var table = $(".dataTable_server_side");
  //table = window.document.querySelector('[id^=DataTables_Table]')
  try {
    //table.DataTable().state.clear()
    table.DataTable().destroy();
    //
  } catch {
    console.log("Se rompio el dataTable.");
  }
};

window.destroy_dataTableObject = destroy_dataTableObject;

const generic_server_side = ({ class_name, path }) => {
  //Datatable serverside

  destroy_dataTableObject.call();
  let table = $(class_name);
  table.DataTable({
    rowCallback: function (row, data, index) {
      // Agregar la clase personalizada a todas las filas
      $(row).addClass("custom-border");
    },
    responsive: true,
    pageLength: 25,
    aaSorting: [],
    language: dT_language,
    paging: true,
    searching: true,
    bDestroy: true,
    info: true,
    processing: true,
    serverSide: true,
    bStateSave: false,
    scrollX: true,
    columnDefs: [
      {
        targets: "no-sort",
        orderable: false,
      },
    ],
    search: {
      return: true,
    },
    ajax: {
      type: "POST",
      url: path,
    },
    error: function (xhr, jqAjaxerror, thrown) {
      show_toast({ type: "danger", message: "Ha ocurrido un error" });
    },
  });
  //Estilo de buscador
  var container = $(".dataTables_length").parent().parent();
  if (container !== undefined) {
    container.attr("class", "d-flex justify-content-around mt-2 mb-1 w-100");
    var alignment = container.find(".col-sm-12");
    alignment.first().attr("class", "d-flex justify-content-start w-50 ml-2");
    alignment.last().attr("class", "d-flex justify-content-end w-50 mr-2");
  }
  // End Datatable serverside
};

window.generic_server_side = generic_server_side;

window.inicializarPagyDataTable = function (clase) {
  $(clase).DataTable({
    responsive: false,
    aaSorting: [],
    language: dT_language,
    paging: false,
    searching: true,
    pageLength: 25,
    lengthMenu: [10, 25, 50, 75, 100],
    columnDefs: [
      {
        targets: "no-sort",
        orderable: false,
      },
    ],
  });
  $(clase).parent().addClass("table_scroll");
};

window.init_dataTable_cuotas = function (clase, path, type) {
  $(clase).DataTable({
    rowCallback: function (row, data, index) {
      // Agregar la clase personalizada a todas las filas
      $(row).addClass("custom-border");
    },
    processing: true,
    pageLength: 25,
    serverSide: true,
    searching: false,
    paging: true,
    language: dT_language,
    order: [],
    lengthMenu: [10, 25, 50, 75, 100],
    ajax: {
      url: path,
      type: type,
      dataSrc: function (response) {
        response.recordsTotal = response.metadata.total_records;
        response.recordsLength = response.metadata.length;
        response.recordsFiltered = response.metadata.total_records;
        response.totalPages = response.metadata.total_pages;
        return response.data;
      },
    },
    columns: [
      {
        orderable: true,
        data: "id_recupero_siduv",
      },
      {
        orderable: false,
        data: "municipio",
      },
      {
        orderable: true,
        data: "codigo_municipio",
      },
      {
        orderable: true,
        data: "nro_cuota",
      },
      {
        orderable: true,
        data: "periodo_formated",
      },
      {
        orderable: true,
        data: "fecha_primer_vencimiento",
      },
      {
        orderable: true,
        data: "amortizacion",
      },
      {
        orderable: false,
        data: "actualizacion",
      },
      {
        orderable: true,
        data: "punitorios",
      },
      {
        orderable: true,
        data: "monto_cuota",
        render: function (data, type, row) {
          return `<b class="text-black">${row.monto_cuota}</b>`;
        },
      },
      {
        orderable: true,
        data: "valor_cuota_reliquidacion",
      },
      {
        orderable: true,
        data: "deuda",
      },
      {
        orderable: false,
        data: "media_deuda",
      },
      {
        orderable: true,
        data: "fecha_emision",
      },
      {
        orderable: true,
        data: "fecha_de_pago",
      },
      {
        orderable: true,
        data: "estado",
        render: function (data, type, row) {
          return `<span class=${row.estado_color}>${row.estado}</span>`;
        },
      },
      {
        orderable: true,
        data: "indice_cvs_mes_emision",
      },
      {
        orderable: true,
        data: "coeficiente_de_actualizacion_a_aplicar",
      },
      {
        orderable: false,
        data: null,
        render: function (data, type, row) {
          return (
            '<div class="p-0">' +
            '<a href="/gestion_financiamiento_y_recupero/cuotas/' +
            data.id_siduv +
            "/reiniciar_cuota?nro_cuota=" +
            data.nro_cuota +
            '" ' +
            data.hidden_button +
            ' data-remote=true class=" '+ data.hidden_for_rol +' btn-warning-rgba btn-xs btn-rounded"><i class="fa fa-reply"></i></a>'
          );
          ("</div>");
        },
      },
    ],
  });
  $(clase).parent().addClass("table_scroll");
};

window.init_dataTable_find_cuotas_a_emitir = function (clase, path, type) {
  $(document)
    .find(clase)
    .DataTable({
      rowCallback: function (row, data, index) {
        // Agregar la clase personalizada a todas las filas
        $(row).addClass("custom-border");
      },
      processing: true,
      serverSide: true,
      searching: false,
      paging: true,
      language: dT_language,
      lengthMenu: [10, 25, 50, 75, 100],
      ajax: {
        url: path,
        type: type,
        dataSrc: function (response) {
          response.recordsTotal = response.metadata.total_records;
          response.recordsLength = response.metadata.length;
          response.recordsFiltered = response.metadata.total_records;
          response.totalPages = response.metadata.total_pages;

          var botonEmision = $("#emision_button");

          // le manda al link de emision con los parametros directamente
          botonEmision.text(` Emitir (${response.metadata.total_records})`);
          botonEmision.prepend('<i class="fa fa-refresh"></i>');
          botonEmision.removeClass("disabled");

          botonEmision.attr("href", response.link);

          return response.data;
        },
      },
      columns: [
        {
          orderable: true,
          data: "id_recupero_siduv",
        },
        {
          orderable: false,
          data: "descripcion_recupero",
        },
        {
          orderable: false,
          data: "municipio",
        },
        {
          orderable: false,
          data: "codigo_municipio",
        },
        {
          orderable: true,
          data: "nro_cuota",
        },
        {
          orderable: true,
          data: "periodo_formated",
        },
        {
          orderable: true,
          data: "fecha_primer_vencimiento",
        },
        {
          orderable: true,
          data: "monto_cuota",
        },
        {
          orderable: true,
          data: "estado",
          render: function (data, type, row) {
            return to_humanized(data);
          },
        },
        {
          orderable: true,
          data: "importe_pagado",
          render: function (data, type, row) {
            return to_currency(data);
          },
        },
        {
          orderable: true,
          data: "fecha_de_pago",
        },
      ],
    });
};

window.initializeObrasDataTableRemote = function (clase, path) {
  $(clase).DataTable({
    rowCallback: function (row, data, index) {
      // Agregar la clase personalizada a todas las filas
      $(row).addClass("custom-border");
    },
    serverSide: true,
    order: [],
    searching: true,
    paging: true,
    pageLength: 25,
    language: dT_language,
    lengthMenu: [10, 25, 50, 75, 100],
    ajax: {
      url: path,
      type: "GET",
      dataSrc: function (response) {
        response.recordsTotal = response.metadata.total_records;
        response.recordsLength = response.metadata.length;
        response.recordsFiltered = response.metadata.total_records;
        response.totalPages = response.metadata.total_pages;
        return response.data;
      },
    },
    columns: [
      { orderable: true, data: "id_obra_link" },
      { orderable: false, data: "modalidad" },
      { orderable: true, data: "descripcion" },
      { orderable: false, data: "municipio" },
      { orderable: true, data: "solicitante_presupuestario" },
      { orderable: false, data: "status" },
      { orderable: false, data: "state" },
      { orderable: false, data: "total_uvi" },
      { orderable: false, data: "monto_actualizado" },
      { orderable: false, data: "codigo_obra" },
      { orderable: false, data: "porcentaje_avance_fisico" },
      {
        orderable: false,
        data: null,
        render: function (data, type, row) {
          return (
            '<div class="">' +
            '<a href="/obras/' +
            data.id +
            '/history" data-remote=true class="btn-primary-rgba btn-xs btn-rounded"><i class="fa fa-code-fork"></i></a>' +
            '<a class="btn-success-rgba btn-xs btn-rounded ' +
            data.show_show +
            ' "  target="_blank" href="/obras/' +
            data.id +
            '"><i class="fa fa-eye"></i></a>' +
            '<a href="/obras/' +
            data.id +
            '/edit" class="btn-warning-rgba btn-xs btn-rounded ' +
            data.show_edit +
            '"><i class="fa fa-pencil"></i></a>' +
            '<a class="btn-danger-rgba btn-xs btn-rounded ' +
            data.show_delete +
            '" onclick="deleteObra(' +
            data.id +
            ')" href="/obras/' +
            data.id +
            '"><i class="fa fa-trash"></i></a>' +
            '<a href="https://geo-siduv.vivienda.gba.gob.ar/app/#/obras?id=' +
            data.id_obra +
            "&uid=" +
            data.current_user +
            '" class="btn-info-geo btn-xs btn-rounded ' +
            data.show_geosiduv +
            '" target="_blank"><i class="fa fa-globe"></i></a>' +
            '<a class="btn-info-rgba btn-xs btn-rounded' +
            data.show_edit +
            '" ' +
            data.show_apta_para_recupero_button +
            ' data-disabled="true" onclick="sendAptaParaRecupero(' +
            data.id +
            ')" href="/obras/' +
            data.id +
            '/send_apta_para_recupero" data-remote="true"><b>R</b></a>' +
            "</div>"
          );
        },
      },
    ],
  });
  $(clase).parent().addClass("table_scroll");
};

window.sendAptaParaRecupero = function (id) {
  $.ajax({
    url: "/obras/" + id + "/send_apta_para_recupero",
    method: "PUT",
    success: function (resultado) {
      show_toast({ type: resultado["type"], message: resultado["message"] });
      location.reload(true);
    },
    error: function () {
      show_toast({ type: "alert", message: resultado["message"] });
    },
  });
};

window.deleteObra = function (id) {
  $.ajax({
    url: "/obras/" + id,
    method: "DELETE",
    success: function (resultado) {
      show_toast({ type: "success", message: "La obra ha sido eliminada." });
    },
    error: function () {
      show_toast({
        type: "alert",
        message: "La obra contiene un Recupero asociado",
      });
    },
  });
};

window.finishObra = function (id) {
  $.ajax({
    url: "/obras/" + id + "/finish",
    method: "PUT",
    success: function (resultado) {},
    error: function () {
      show_toast({
        type: "alert",
        message: "No puede transicionar a ese estado aún",
      });
    },
  });
};

window.executeObra = function (id) {
  $.ajax({
    url: "/obras/" + id + "/execute",
    method: "PUT",
    success: function (resultado) {},
    error: function () {
      show_toast({
        type: "alert",
        message: "No puede transicionar a ese estado aún",
      });
    },
  });
};

window.initializeRecuperosDataTableRemote = function (clase, path) {
  $(clase).DataTable({
    rowCallback: function (row, data, index) {
      // Agregar la clase personalizada a todas las filas
      $(row).addClass("custom-border");
    },
    serverSide: true,
    pageLength: 25,
    searching: true,
    paging: true,
    language: dT_language,
    lengthMenu: [10, 25, 50, 75, 100],
    ajax: {
      url: path,
      type: "GET",
      dataSrc: function (response) {
        response.recordsTotal = response.metadata.total_records;
        response.recordsLength = response.metadata.length;
        response.recordsFiltered = response.metadata.total_records;
        response.totalPages = response.metadata.total_pages;
        return response.data;
      },
    },
    columns: [
      { orderable: true, data: "id_recupero" },
      { orderable: false, data: "codigo_obras" },
      { orderable: true, data: "descripcion" },
      { orderable: true, data: "tipo" },
      { orderable: true, data: "viviendas_en_recupero" },
      { orderable: true, data: "q_uvis_viviendas_en_recupero" },
      { orderable: true, data: "fecha_alta_recupero" },
      { orderable: true, data: "fecha_cotizacion_uvi" },
      { orderable: true, data: "valor_uvis_para_recupero" },
      { orderdable: false, data: "monto_total_recupero_origen" },
      { orderdable: false, data: "indice_cvs_recupero" },
      { orderdable: false, data: "fecha_indice_cvs_recupero" },
      {
        orderdable: false,
        data: "estado_recupero",
        render: function (data, type, row) {
          var estateClass = window.recuperoColorState[data.toLowerCase().replaceAll(" ","")];
          return '<span class="' + estateClass + '">' + data + "</span>";
        },
      },
      {
        orderable: false,
        data: null,
        render: function (data, type, row) {
          return (
            '<div class="">' +
            '<a href="/obras/' +
            data.obra_id +
            '#recupero" id="link_to_recupero" class="btn-success-rgba btn-xs btn-rounded"><i class="fa fa-eye"></i></a>' +
            '<a href="/obras/' +
            data.obra_id +
            "/recuperos/" +
            data.id +
            '/edit"  class="btn-warning-rgba btn-xs btn-rounded ' +
            data.disabled_button +
            " " +
            data.show_edit +
            '"><i class="fa fa-pencil"></i></a>' +
            '<a href="/obras/' +
            data.obra_id +
            "/recuperos/" +
            data.id +
            '" data-method="delete" class="btn-danger-rgba btn-xs btn-rounded ' +
            data.disabled_button +
            " " +
            data.show_delete +
            '"><i class="fa fa-trash"></i></a>' +
            "</div>"
          );
        },
      },
    ],
  });
  $(clase).parent().addClass("table_scroll");
};

window.initializeAdjudicatariosDataTableRemote = function (clase, path) {
  $(clase).DataTable({
    rowCallback: function (row, data, index) {
      // Agregar la clase personalizada a todas las filas
      $(row).addClass("custom-border");
    },
    serverSide: true,
    pageLength: 25,
    searching: true,
    paging: true,
    language: dT_language,
    lengthMenu: [10, 25, 50, 75, 100],
    ajax: {
      url: path,
      type: "GET",
      dataSrc: function (response) {
        response.recordsTotal = response.metadata.total_records;
        response.recordsLength = response.metadata.length;
        response.recordsFiltered = response.metadata.total_records;
        response.totalPages = response.metadata.total_pages;
        return response.data;
      },
    },
    columns: [
      {
        orderable: false,
        data: null,
        render: function (data, type, row) {
          return (
            "<td>" +
            '<input style="display: block;" class="adjudicatario-select" type="checkbox" name="seleccionados[]" value="' +
            data.id +
            '" >' +
            "</td>"
          );
        },
      },
      { orderable: true, data: "municipio" },
      { orderable: false, data: "link_titular_dni" },
      { orderable: false, data: "titular_apellidos_nombres" },
      { orderable: true, data: "detalle_barrio" },
      { orderable: true, data: "codigo_barrio" },
      { orderable: true, data: "codigo_obra" },
      { orderable: false, data: "con_escritura" },
      { orderable: false, data: "con_plano" },
      { orderable: true, data: "fecha_cancelacion" },
      { orderable: true, data: "manzana" },
      { orderable: true, data: "parcela" },
      { orderable: true, data: "tipo_ubicacion" },
      { orderable: true, data: "calle" },
      { orderable: true, data: "numero_calle" },
      { orderable: true, data: "piso" },
      { orderable: true, data: "departamento" },
      { orderable: true, data: "dato_id_adjudicatario" },
      { orderable: false, data: "id_recupero" },
      { orderable: false, data: "tipo_recupero" },
    ],
  });
  $(clase).parent().addClass("table_scroll");
};

window.initializeReporteAdjudicatariosDataTableRemote = function (clase, path) {
  $(clase).DataTable({
    rowCallback: function (row, data, index) {
      // Agregar la clase personalizada a todas las filas
      $(row).addClass("custom-border");
    },
    serverSide: true,
    pageLength: 25,
    searching: true,
    paging: true,
    language: dT_language,
    lengthMenu: [10, 25, 50, 75, 100],
    ajax: {
      url: path,
      type: "GET",
      dataSrc: function (response) {
        response.recordsTotal = response.metadata.total_records;
        response.recordsLength = response.metadata.length;
        response.recordsFiltered = response.metadata.total_records;
        response.totalPages = response.metadata.total_pages;
        return response.data;
      },
    },
    columns: [
      { orderable: true, data: "municipio" },
      { orderable: false, data: "link_titular_dni" },
      { orderable: false, data: "titular_apellidos_nombres" },
      { orderable: true, data: "detalle_barrio" },
      { orderable: true, data: "codigo_barrio" },
      { orderable: true, data: "codigo_obra" },
      { orderable: false, data: "con_escritura" },
      { orderable: false, data: "con_plano" },
      { orderable: true, data: "fecha_cancelacion" },
      { orderable: true, data: "manzana" },
      { orderable: true, data: "parcela" },
      { orderable: true, data: "tipo_ubicacion" },
      { orderable: true, data: "calle" },
      { orderable: true, data: "numero_calle" },
      { orderable: true, data: "piso" },
      { orderable: true, data: "departamento" },
      { orderable: true, data: "dato_id_adjudicatario" },
      { orderable: false, data: "id_recupero" },
      { orderable: false, data: "tipo_recupero" },
      {
        orderable: false,
        data: null,
        render: function (data, type, row) {
          return (
            '<div class="row justify-content-center p-2">' +
            '<a href="/adjudicatarios/' +
            data.id +'" data-method="delete" data-confirm="¿Estás seguro de que deseas eliminar este adjudicatario?" class="btn-danger-rgba btn-xs btn-rounded ' +
            data.show_delete_button +
            '"><i class="fa fa-trash"></i></a>' +
            "</div>"
          );
        },
      },
    ],
  });
  $(clase).parent().addClass("table_scroll");
};

window.initializeSigafPaymentsDataTableRemote = function (clase, path) {
  $(clase).DataTable({
    rowCallback: function (row, data, index) {
      // Agregar la clase personalizada a todas las filas
      $(row).addClass("custom-border");
    },
    serverSide: true,
    searching: true,
    paging: true,
    pageLength: 25,
    language: dT_language,
    lengthMenu: [10, 25, 50, 75, 100],
    ajax: {
      url: path,
      type: "GET",
      dataSrc: function (response) {
        response.recordsTotal = response.metadata.total_records;
        response.recordsLength = response.metadata.length;
        response.recordsFiltered = response.metadata.total_records;
        response.totalPages = response.metadata.total_pages;
        return response.data;
      },
    },
    columns: [
      { orderable: true, data: "numero_expediente" },
      { orderable: true, data: "trata" },
      { orderable: true, data: "q_devengados_sin_pagar" },
      { orderable: true, data: "q_pagados" },
      { orderable: true, data: "codigo_obra_cabecera" },
      { orderable: true, data: "codigo_obra_auxiliar" },
      { orderable: true, data: "codigo_obra_caratula" },
      { orderable: false, data: "id_obra_link" },
      {
        orderable: false,
        data: null,
        render: function (data, type, row) {
          return (
            '<div class="row justify-content-center p-2">' +
            "<a href=" +
            data.link_to_edit +
            ' id=edit_modal data-remote=true class="btn-primary-rgba btn-xs btn-rounded"><i class="fa fa-external-link"></i></a>' +
            "</div>"
          );
        },
      },
    ],
  });
  $(clase).parent().addClass("table_scroll");
};

window.initializePaymentOrderDataTableRemote = function (clase, path) {
  $(clase).DataTable({
    rowCallback: function (row, data, index) {
      // Agregar la clase personalizada a todas las filas
      $(row).addClass("custom-border");
    },
    order: [],
    serverSide: true,
    searching: true,
    paging: true,
    language: dT_language,
    lengthMenu: [10, 25, 50, 75, 100],
    ajax: {
      url: path,
      type: "GET",
      dataSrc: function (response) {
        response.recordsTotal = response.metadata.total_records;
        response.recordsLength = response.metadata.length;
        response.recordsFiltered = response.metadata.total_records;
        response.totalPages = response.metadata.total_pages;
        return response.data;
      },
    },
    columns: [
      { orderable: false, data: "id_obra_link" },
      { orderable: false, data: "id_historico" },
      { orderable: true, data: "numero_expediente" },
      { orderable: true, data: "trata" },
      { orderable: false, data: "codigo_obra" },
      { orderable: false, data: "origen" },
      { orderable: false, data: "ejercicio_numero_op" },
      { orderable: false, data: "descripcion" },
      { orderable: false, data: "ente" },
      { orderable: false, data: "estado" },
      { orderable: false, data: "importe_devengado" },
      { orderable: false, data: "fecha_imputacion" },
      { orderable: false, data: "fecha_pago" },
      {
        orderable: false,
        data: null,
        render: function (data, type, row) {
          return (
            '<div class="row justify-content-center p-2">' +
            "<a href=" +
            data.link_to_edit +
            ' id=edit_modal_payment_order data-remote=true class="' +
            data.show_edit +
            ' btn btn-primary-rgba btn-xs btn-rounded"><i class="fa fa-external-link"></i></a>' +
            "</div>"
          );
        },
      },
    ],
  });
  $(clase).parent().addClass("table_scroll");
};

window.inicializarDataTablePaginated = function (clase) {
  $(clase).DataTable({
    paging: true,
    responsive: false,
    aaSorting: [],
    pageLength: 25,
    language: dT_language,
    columnDefs: [
      {
        targets: "no-sort",
        orderable: false,
      },
    ],
  });
  $(clase).parent().addClass("table_scroll");
};

window.inicializarDataTableUsuarios = function (clase) {
  $(clase).DataTable({
    responsive: false,
    aaSorting: [],
    language: dT_language,
    paging: false,
    searching: false,
    columnDefs: [
      {
        targets: "no-sort",
        orderable: false,
      },
    ],
  });
  $(clase).parent().addClass("table_scroll");
};

window.downloadFile = function (url, download) {
  var link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.download = download;
  link.click();
};

window.initialize_select2 = function (
  id,
  multiple,
  placeholder,
  minLength = 3
) {
  $(`#${id}`).select2({
    width: "100%",
    allowClear: true,
    multiple: multiple,
    placeholder: placeholder,
    language: "es",
    minimumInputLength: minLength,
    language: {
      noResults: function () {
        return "No se encontraron resultados";
      },
      searching: function () {
        return "Buscando...";
      },
      inputTooShort: function () {
        return `Ingrese al menos ${minLength} caracteres`;
      },
    },
  });
};

window.initialize_remote_select2 = function (
  id,
  path,
  multiple,
  placeholder,
  minLength = 3,
  parent = false
) {
  $(`#${id}`).select2({
    width: "100%",
    dropdownParent: parent,
    allowClear: true,
    placeholder: placeholder,
    language: "es",
    minimumInputLength: minLength,
    multiple: multiple,
    language: {
      noResults: function () {
        return "No se encontraron resultados";
      },
      searching: function () {
        return "Buscando...";
      },
      inputTooShort: function () {
        return `Ingrese al menos ${minLength} caracteres`;
      },
    },
    ajax: {
      url: path,
      dataType: "json",
      data: function (params) {
        return {
          q: params.term,
        };
      },
      processResults: function (response) {
        return {
          results: $.map(response, function (item) {
            return {
              text: item.text,
              id: item.id,
            };
          }),
        };
      },
    },
  });
};

window.initialize_remote_status_options = function (
  id,
  path,
  id_estado,
  placeholder = "",
  allow_clear = true
) {
  $(`#${id}`).select2({
    width: "resolve",
    language: {
      noResults: function () {
        return "No se encontraron resultados";
      },
      searching: function () {
        return "Buscando...";
      },
    },
    allowClear: allow_clear,
    placeholder: placeholder,
    multiple: true,
    ajax: {
      url: path,
      dataType: "json",
      data: function (params) {
        var estado = $(`#${id_estado}`).val();
        return {
          q: params.term,
          estado: estado,
        };
      },
      processResults: function (response) {
        return {
          results: $.map(response, function (item) {
            return {
              text: item.text,
              id: item.id,
            };
          }),
        };
      },
    },
  });
};

$(".date_field").datetimepicker({
  locale: "es",
  toolbarPlacement: "top",
  showTodayButton: true,
  showClear: true,
  showClose: true,
  widgetPositioning: {
    horizontal: "right",
    vertical: "bottom",
  },
  inline: false,
  format: "L",
});

dataConfirmModal.setDefaults({
  title: "Confirmar acción",
  cancel: "Cancelar",
  commit: "Continuar",
});

function limitPorcentValue() {
  $(".porcentlimited").on({
    keyup: function (event) {
      $(event.target).val(function (index, value) {
        if (value > 100) {
          $(this).val(100);
        } else {
          return parseInt(value);
        }
      });
    },
  });
}

$(document).on("focus keyup focusout", ".numberseparator", function (event) {
  if (event.type === "focus") {
    $(event.target).select();
  } else if (event.type === "keyup") {
    $(event.target).val(function (index, value) {
      return value
        .replace(/\D/g, "")
        .replace(/([0-9])([0-9]{2})$/, "$1,$2")
        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    });
  } else if (event.type === "focusout") {
    $(event.target).val(function (index, value) {
      return value;
    });
  }
});

$(document).on("submit", "form", function () {
  $(".numberseparator").each(function () {
    if ($(this).val().length > 0) {
      var normalized_price = $(this)
        .val()
        .replaceAll(".", "")
        .replaceAll(",", ".");
      $(this).val(normalized_price);
    }
  });
});

$(document).on("focus keyup focusout", ".manydecimals", function (event) {
  if (event.type === "focus") {
    $(event.target).select();
  } else if (event.type === "keyup") {
    $(event.target).val(function (index, value) {
      // Eliminar caracteres no numéricos
      value = value.replace(/[^\d.]/g, "");
      // Dividir el valor en parte entera y parte decimal
      var parts = value.split(".");
      // Si hay parte entera y no hay parte decimal, añadir un punto decimal
      if (parts.length === 1 && parts[0].length > 0) {
        parts.push("");
      }
      // Asegurar que la parte decimal tenga máximo 16 dígitos
      if (parts.length > 1) {
        parts[1] = parts[1].substring(0, 15);
      }
      // Reunir la parte entera y la parte decimal con el separador adecuado
      return parts.join(".");
    });
  } else if (event.type === "focusout") {
    $(event.target).val(function (index, value) {
      return value;
    });
  }
});

window.initializeObrasComputoRemoteDatatable = function (clase, path) {
  $(clase).DataTable({
    rowCallback: function (row, data, index) {
      // Agregar la clase personalizada a todas las filas
      $(row).addClass("custom-border");
    },
    serverSide: true,
    pageLength: 25,
    searching: true,
    paging: true,
    language: dT_language,
    lengthMenu: [10, 25, 50, 75, 100],
    ajax: {
      url: path,
      type: "GET",
      dataSrc: function (response) {
        response.recordsTotal = response.metadata.total_records;
        response.recordsLength = response.metadata.length;
        response.recordsFiltered = response.metadata.total_records;
        response.totalPages = response.metadata.total_pages;
        return response.data;
      },
    },
    columns: [
      { orderable: false, data: "id_obra" },
      { orderable: true, data: "modalidad" },
      { orderable: false, data: "descripcion" },
      { orderable: true, data: "municipio" },
      { orderable: true, data: "solicitante_presupuestario" },
      { orderable: true, data: "status" },
      { orderable: true, data: "state" },
      { orderable: false, data: "codigo_obra" },
    ],
  });
  $(clase).parent().addClass("table_scroll");
};

window.initializeItemComputoPresupuestoRemoteDatatable = function (
  clase,
  path
) {
  $(clase).DataTable({
    rowCallback: function (row, data, index) {
      // Agregar la clase personalizada a todas las filas
      $(row).addClass("custom-border");
    },
    serverSide: true,
    pageLength: 25,
    searching: true,
    paging: true,
    language: dT_language,
    lengthMenu: [10, 25, 50, 75, 100],
    ajax: {
      url: path,
      type: "GET",
      dataSrc: function (response) {
        response.recordsTotal = response.metadata.total_records;
        response.recordsLength = response.metadata.length;
        response.recordsFiltered = response.metadata.total_records;
        response.totalPages = response.metadata.total_pages;
        return response.data;
      },
    },
    columns: [
      { orderable: false, data: "nombre" },
      {
        orderable: true,
        data: "viviendas_un",
        render: function (data, type, row) {
          return parseFloat(data).toLocaleString("es-ES", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        },
      },
      {
        orderable: false,
        data: "superficie_m2",
        render: function (data, type, row) {
          return parseFloat(data).toLocaleString("es-ES", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        },
      },
      { orderable: false, data: "fecha_uvi" },
      {
        orderable: true,
        data: "valor_uvi",
        render: function (data, type, row) {
          return parseFloat(data).toLocaleString("es-ES", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        },
      },
      {
        orderable: false,
        data: null,
        render: function (data, type, row) {
          return (
            '<div class="row p-2">' +
            '<a href="/item_computo_presupuestos/' +
            data.id +
            '/edit" class="btn-warning-rgba btn-xs btn-rounded ' +
            data.show_edit +
            '"><i class="fa fa-pencil"></i></a>' +
            '<a class="btn-success-rgba btn-xs btn-rounded"  target="_blank" href="/item_computo_presupuestos/' +
            data.id +
            '"><i class="fa fa-eye"></i></a>' +
            '<a href="/obras/' +
            data.obra_id +
            "/item_computo_presupuestos/" +
            data.id +
            '" data-method="delete" class="btn-danger-rgba btn-xs btn-rounded ' +
            data.show_delete +
            '"><i class="fa fa-trash"></i></a>' +
            "</div>"
          );
        },
      },
    ],
  });
  $(clase).parent().addClass("table_scroll");
};

window.initializeObrasItemComputoRemoteDatatable = function (clase, path) {
  $(clase).DataTable({
    rowCallback: function (row, data, index) {
      // Agregar la clase personalizada a todas las filas
      $(row).addClass("custom-border");
    },
    serverSide: true,
    pageLength: 25,
    searching: true,
    paging: true,
    language: dT_language,
    lengthMenu: [10, 25, 50, 75, 100],
    ajax: {
      url: path,
      type: "GET",
      dataSrc: function (response) {
        response.recordsTotal = response.metadata.total_records;
        response.recordsLength = response.metadata.length;
        response.recordsFiltered = response.metadata.total_records;
        response.totalPages = response.metadata.total_pages;
        return response.data;
      },
    },
    columns: [
      { orderable: false, data: "id_obra_link_cyp" },
      { orderable: true, data: "modalidad" },
      { orderable: false, data: "descripcion" },
      { orderable: true, data: "municipio" },
      { orderable: true, data: "solicitante_presupuestario" },
      { orderable: true, data: "status" },
      { orderable: true, data: "state" },
      { orderable: true, data: "codigo_obra" }
    ],
  });
  $(clase).parent().addClass("table_scroll");
};

window.initializeCustomScrollbar = function (
  tableScrollSelector,
  scrollbarSelector,
  scrollbarThumbSelector,
  maxWidth
) {
  var $tableScroll = $(tableScrollSelector);
  var $scrollbar = $(scrollbarSelector);
  var $scrollbarThumb = $(scrollbarThumbSelector);

  function updateScrollbar() {
    var parentWidth = $tableScroll.parent().width();

    if (parentWidth < maxWidth) {
      $scrollbar.show();
      $scrollbar.css("width", parentWidth * 0.85 + "px");
    } else {
      $scrollbar.hide();
    }
  }

  function updateThumbWidth() {
    var thumbWidth =
      ($scrollbar.width() * $scrollbar.width()) / $tableScroll[0].scrollWidth;
    $scrollbarThumb.css("width", thumbWidth + "px");
  }

  function handleScroll() {
    var scrollLeft = $tableScroll.scrollLeft();
    var scrollPercentage =
      scrollLeft / ($tableScroll[0].scrollWidth - $tableScroll.width());
    var thumbLeft =
      scrollPercentage * ($scrollbar.width() - $scrollbarThumb.width());
    $scrollbarThumb.css("left", thumbLeft + "px");
  }

  function handleThumbDrag(e) {
    var startX = e.pageX;
    var startLeft = parseInt($scrollbarThumb.css("left"), 10);

    $(document).on("mousemove.scrollbar", function (e) {
      var deltaX = e.pageX - startX;
      var newLeft = startLeft + deltaX;
      var maxLeft = $scrollbar.width() - $scrollbarThumb.width();

      if (newLeft < 0) newLeft = 0;
      if (newLeft > maxLeft) newLeft = maxLeft;

      $scrollbarThumb.css("left", newLeft + "px");

      var scrollPercentage = newLeft / maxLeft;
      var newScrollLeft =
        scrollPercentage * ($tableScroll[0].scrollWidth - $tableScroll.width());
      $tableScroll.scrollLeft(newScrollLeft);
    });

    $(document).on("mouseup.scrollbar", function () {
      $(document).off(".scrollbar");
    });

    return false;
  }

  updateScrollbar();
  updateThumbWidth();

  $tableScroll.on("scroll", handleScroll);
  $scrollbarThumb.on("mousedown", handleThumbDrag);
  $(window).on("resize", function () {
    updateScrollbar();
    updateThumbWidth();
  });
};

window.initialize_remote_select2_paginated = function (
  id,
  path,
  multiple,
  placeholder,
  minLength = 3,
  parent = false
) {
  let page = 1;

  $(`#${id}`).select2({
    width: "100%",
    dropdownParent: parent,
    allowClear: true,
    placeholder: placeholder,
    language: "es",
    minimumInputLength: minLength,
    multiple: multiple,
    language: {
      noResults: function () {
        return "No se encontraron resultados";
      },
      searching: function () {
        return "Buscando...";
      },
      inputTooShort: function () {
        return `Ingrese al menos ${minLength} caracteres`;
      },
    },
    ajax: {
      url: path,
      dataType: "json",
      delay: 10,
      data: function (params) {
        return {
          q: params.term,
          page: params.page || 1,
        };
      },
      processResults: function (response, params) {
        params.page = params.page || 1;

        return {
          results: $.map(response.items, function (item) {
            return {
              text: item.text,
              id: item.id,
            };
          }),
          pagination: {
            more: response.hasMore,
          },
        };
      },
    },
    escapeMarkup: function (markup) {
      return markup;
    },
    cache: true,
  });
};

window.showSpinner = function () {
  document.getElementById("spinner").style.display = "flex";
};

window.hideSpinner = function () {
  document.getElementById("spinner").style.display = "none";
};
